class AnalyticsService {

    constructor () {
    }

    log(screen, widget, type) {
        let event = {
            screen: screen,
            widget: widget,
            type: type,
            domain: 'quant-ux.com',
            localCreated:new Date().toISOString
        }
        fetch('/rest/analytics', {
            method: 'post',
            credentials: "same-origin",
            body: JSON.stringify(event)
        })
    }

}
export default new AnalyticsService()