<template>
  <div id="app" class="MatcMainCntr">
    <router-view />
    <div class="vommondMessage" ref="message"></div>
  </div>
</template>
<style>
	@import url("./style/css/legacy.css");
</style>
<script>
import css from "dojo/css";
import win from "dojo/win";
import Services from "services/Services";
import AnalyticsService from "services/AnalyticsService";
import on from "dojo/on";
import touch from "dojo/touch";
import lang from "dojo/_base/lang";
import Dialog from "common/Dialog";
import DomBuilder from "common/DomBuilder";
import Logger from "common/Logger";


export default {
  data: function() {
    return {
      errorDialogOpen: false
    };
  },
  methods: {
    showSuccess (msg) {
      if (this.$refs.message) {
        css.add(this.$refs.message, "vommondMessageSuccess");
        css.remove(this.$refs.message, "vommondMessageError vommondMessageHint");
        this.$refs.message.innerHTML = msg;
        setTimeout(() => {
          this.hideMessage();
        }, 2000);
      }
    },
    showError (msg) {
      if (this.$refs.message) {
        css.add(this.$refs.message, "vommondMessageError");
        css.remove( this.$refs.message,"vommondMessageSuccess vommondMessageHint");
        this.$refs.message.innerHTML = msg;
        setTimeout(() => {
          this.hideMessage();
        }, 4000);
      }
    },
    showHint (msg) {
      if (this.$refs.message) {
        css.add(this.$refs.message, "vommondMessageHint");
        css.remove( this.$refs.message, "vommondMessageSuccess vommondMessageSuccess");
        this.$refs.message.innerHTML = msg;
        setTimeout(() => {
          this.hideMessage();
        }, 4000);
      }
    },
    hideMessage () {
      css.remove(this.$refs.message,"vommondMessageSuccess vommondMessageError vommondMessageHint");
    },
    checkURL4SSO () {
      if (location.pathname !== '/') {
        const path = location.pathname
        this.$router.push(path)
      }
    },
    show4xxDialog (header, message) {
      if (!this.errorDialogOpen) {
          this.errorDialogOpen = true
          const db = new DomBuilder();
          const div = db.div("MatcDialog MatcPadding").build();
          db.h2(null, header).build(div);
          db.p("MatHint", message);
          const bar = db.div("MatcButtonBar").build(div);
          const cancel = db.a("MatcButton", "Close").build(bar);
          const d = new Dialog();
          d.own(on(cancel, touch.release, lang.hitch(d, "close")));
          d.own(on(d, "close", () => {
              this.logger.log(-1, "show4xxDialog()", "exit >> ");
              this.errorDialogOpen = false;
              Services.getUserService().logout();
              this.$router.push("/");
              location.reload()
            })
          );
          d.popup(div);
        }
    },
    handler4xx(url, res) {
      this.logger.error("handler4xx", "enter >> " + this.errorDialogOpen, res);
      if (res.status === 401 || res.status === 404) {
        this.show4xxDialog("Ooops...", "Something went wrong. Please login again.")
      }

      if (res.tokenTimedOut) {
        this.show4xxDialog("Ooops...", "Your session has expired. Please login again.")
      }
    }
  },
  watch: {
    $route() {
      if (this.$route.meta.isHome) {
        css.add(win.body(), "MatcHome");
      } else {
        css.remove(win.body(), "MatcHome");
      }
      if (this.$route.meta.isLanding) {
        css.add(win.body(), "MatcLanding");
      } else {
        css.remove(win.body(), "MatcLanding");
      }

      if (this.$route.meta.analytics) {
        let widget = ''
        if (this.$route.meta.analyticsKey) {
          const key = this.$route.meta.analyticsKey
          widget = this.$route.params[key]
        }
        AnalyticsService.log(this.$route.name, widget, 'view')
      }
    },
  },
  mounted() {
    this.logger = new Logger("App");
    Services.setErrorHandler((url, res) => {
      this.handler4xx(url, res);
    });
    this.$root.$on("Success", (msg) => {
      this.showSuccess(msg);
    });
    this.$root.$on("Error", (msg) => {
      this.showError(msg);
    });
    this.$root.$on("Hint", (msg) => {
      this.showHint(msg);
    });
    this.$root.$on("UserLogin", (user) => {
      Services.getUserService().setUser(user);
    });
    css.add(win.body(), "MatcLanding");
    css.remove(win.body(), "MatcPublic");
    this.checkURL4SSO()
    
  },
};
</script>

