<template>
  <div class="Matc">
    <LoginPage v-if="isGuest &&!isPublic" :user="user" @login="onLogin"/>
    <div class="MatcContainer" v-else>
      <Header :user="user" @login="onLogin" @logout="onLogout"  v-if="hasHeader" />
      <router-view @logout="onLogout"></router-view>
    </div>
  </div>
</template>
<style lang="scss">
  @import "../style/matc.scss";
  @import "../style/qux.scss";
</style>
<style lang="sass">
  @import "../style/bulma.sass"
</style>

<script>
import Header from "views/Header";
import Services from "services/Services";
import Logger from "common/Logger";
import win from "dojo/win";
import css from "dojo/css";
import LoginPage from 'views/LoginPage'

export default {
  name: "home",
  mixins: [],
  data: function() {
    return {
      hasHeader: false,
      hasCookieWarning: true,
      user: {
        id: -1,
        name: "Guest",
        email: "guest@quant-ux.com",
        role: "guest",
        lastlogin: 0,
        lastNotification: 0,
        tos: false,
        paidUntil: 0,
        plan: "Free"
      },
      error401Count: 0
    };
  },
  computed: {
    isGuest () {
      return this.user.role === 'guest'
    },
    isPublic () {
      return this.$route.meta && this.$route.meta.isPublic
    }
  },
  components: {
    Header: Header,
    LoginPage: LoginPage
  },
  methods: {
    onLogin(user) {
      this.user = user;
      this.logger.info("onLogin", "exit >> " + this.user.email);
      this.error401Count = 0;
    },
    onLogout(guest) {
      this.user = guest;
      this.logger.info("onLogin", "exit >> " + this.user.email);
      this.error401Count = 0;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    checkToken () {
      this.logger.log(1, "checkToken", "enter >> ", this.user);
      if (this.user && this.user.role !== 'guest') {
        /**
         * Check again that the server really takes the token. So
         * we just load the users profile. If the token is bad,
         * an 404 is thrown, which will logout the user and force
         * a fresh login.
         */
        try {
          Services.getUserService().loadById(this.user.id)
        } catch (e) {
          /** this will aslo call handle4xx method */
          this.logger.log(-1, "checkToken", "error >> ", e);
        }
      }
    }
  },
  watch: {
    $route() {
      css.remove(win.body(), "MatcPublic");
      css.remove(win.body(), "MatcVisualEditor");
      css.remove(win.body(), "MatcLight");
      this.scrollTop();
      if (this.$route.meta.hasHeader === true) {
        this.hasHeader = true
			} else {
        this.hasHeader = false
			}
    }
  },
  async mounted() {
    this.logger = new Logger("MATC");
    this.user = await Services.getUserService().load();
    this.checkToken()
    this.logger.info("mounted", "exit >> ");
  }
};
</script>
