import Vue from 'vue'
import Router from 'vue-router'
import MATC from 'views/MATC.vue'


Vue.use(Router)

const matcRoutes = []

matcRoutes.push({path:'opensource.html',  name: "opensource", meta: {isHome:true, hasHeader: true}, component: () => import(/* webpackChunkName: "about" */ 'views/public/OpenSource.vue')})
matcRoutes.push({path:'contact.html',  name: "contact", meta: {isPublic:true, isHome:true, hasHeader: true}, component: () => import(/* webpackChunkName: "about" */ 'views/public/Contact.vue')})
matcRoutes.push({path:'termsofservice.html',  name: "tos", meta: {isPublic:true, isHome:true, hasHeader: true, analytics:true}, component: () => import(/* webpackChunkName: "about" */ 'views/public/Tos.vue')})
matcRoutes.push({path:'privacy.html',  name: "privacy", meta: {isPublic:true, isHome:true, hasHeader: true, analytics:true}, component: () => import(/* webpackChunkName: "about" */ 'views/public/Privacy.vue')})
matcRoutes.push({path:'404.html', meta: {isPublic:true,}, component: () => import(/* webpackChunkName: "about" */ 'views/404.vue')})

// User stuff
matcRoutes.push({path:'signup-details.html',  name: "signup", meta: {isHome:true, hideHeader: true}, component: () => import(/* webpackChunkName: "about" */ 'views/apps/Studio.vue')})
matcRoutes.push({path:'welcome.html',  name: "welcome", meta: {isHome:true, hideHeader: true}, component: () => import(/* webpackChunkName: "about" */ 'views/apps/Studio.vue')})
matcRoutes.push({path:'my-account.html',  name: "myaccount", meta: {isHome:true, hasHeader: true}, component: () => import(/* webpackChunkName: "about" */ 'views/user/Account.vue')})
matcRoutes.push({path:'reset_password.html', meta: {isHome:true, hasHeader: true}, component: () => import(/* webpackChunkName: "about" */ 'views/user/ResetPassword.vue')})
matcRoutes.push({path:'reset_password3.html', meta: {isHome:true, hasHeader: true}, component: () => import(/* webpackChunkName: "about" */ 'views/user/ResetPassword.vue')})
matcRoutes.push({path:'logout.html', component: () => import(/* webpackChunkName: "about" */ 'views/LogoutPage.vue')})

// Help
matcRoutes.push({path:'help.html', meta: {isPublic:true, isHome:true, hasHeader: true, isLanding:true, analytics:true}, name: "help", component: () => import(/* webpackChunkName: "help" */ 'views/Help.vue')})
matcRoutes.push({path:'help/:topic.html', meta: {isPublic:true, isHome:true, hasHeader: true, isLanding:true}, component: () => import(/* webpackChunkName: "help" */ 'views/Help.vue')})
matcRoutes.push({path:'help/:topic/:subtopic.html',meta: {isPublic:true, isHome:true, hasHeader: true, isLanding:true}, component: () => import(/* webpackChunkName: "help" */ 'views/Help.vue')})


matcRoutes.push({path:'', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Studio.vue'),  meta: {hideHeader: true}})
matcRoutes.push({path:'apps/my-apps.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Studio.vue'), meta: {hideHeader: true, analytics: true}})
matcRoutes.push({path:'apps/logged_in.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Studio.vue'),  meta: {hideHeader: true, analytics: true}})
matcRoutes.push({path:'apps/:id.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Studio.vue'), meta: {hideHeader: true, analytics: true}})
matcRoutes.push({path:'apps/:id/:tab.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Studio.vue'), meta: {hideHeader: true, analytics: true}})
matcRoutes.push({path:'apps/:id/replay/:session.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Studio.vue'), meta: {hideHeader: true, analytics: true}})
matcRoutes.push({path:'apps/create-app.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Create.vue'), meta: {hideHeader: true, analytics: true}})
matcRoutes.push({path:'apps/tryout.html', component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Create.vue'), meta: {hideHeader:true,analytics: true}})

// Apps
matcRoutes.push({path:'examples.html', name: "examples_list", component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Examples.vue'), meta: {isPublic:true, isHome:true, hideHeader: true, analytics: true} })
matcRoutes.push({path:'examples/:id.html', name: "examples_overview", component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Studio.vue'), meta: {isPublic:true,  isHome:true, hideHeader: true, analytics: true}})
matcRoutes.push({path:'examples/:id/:tab.html', name: "examples_overview_tab", component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Studio.vue'), meta: {isPublic:true,  isHome:true, hideHeader: true}})
matcRoutes.push({path:'examples/:id/replay/:session.html', name: "examples_video",  component: () => import(/* webpackChunkName: "apps" */ 'views/apps/Studio.vue'), meta: {isPublic:true,  isHome:true, hideHeader: true}})

// MCU
const mcuRoutes = []
mcuRoutes.push({path:'', name: "mcu_home", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashUsers.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'apps.html', name: "mcu_apps", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Apps.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'users.html', name: "mcu_users", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Users.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'notifications.html', name: "mcu_notifications", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Notifications.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'backups.html', name: "mcu_backups", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Backups.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'log.html', name: "mcu_log", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Log.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-users.html', name: "mcu_dash_users", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashUsers.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-performance.html', name: "mcu_dash_performance", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashPerformance.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-db.html', name: "mcu_dash_db", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashDB.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-metrics.html', name: "mcu_dash_metric", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashMetrics.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-analytics.html', name: "mcu_dash_analytic", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashAnalytics.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'errors.html', name: "mcu_dash_error", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/Errors.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'dash-open-ai.html', name: "mcu_dash_open_ai", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/DashOpenAI.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'users/:id.html', name: "mcu_user", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/User.vue'), meta: {isAdmin:true, isHome:false} })
mcuRoutes.push({path:'restore-backup.html', name: "mcu_restore", component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/RestoreBackup.vue'), meta: {isAdmin:true, isHome:false} })




export default new Router({
  routes: [
    {
      path: '/simulate.html',
      name: 'simulator',
      component: () => import(/* webpackChunkName: "simulator" */ 'views/simulator/SimulatorPage.vue')
    },
    {
      path: '/test.html',
      name: 'test',
      component: () => import(/* webpackChunkName: "test" */ 'views/simulator/TestPage.vue')
    },
    {
      path: '/test/mobile/:id.html',
      name: 'test_mobile',
      component: () => import(/* webpackChunkName: "matc" */ 'views/simulator/TestMobile.vue')
    },
    {
      path: '/apps/:id/create.html',
      name: 'apps_edit_create',
      meta: {analytics: true},
      component: () => import(/* webpackChunkName: "design" */ 'views/apps/Design.vue')
    },
    {
      path: '/apps/:id/design/:sid.html',
      name: 'apps_edit',
      meta: {analytics: true},
      component: () => import(/* webpackChunkName: "design" */ 'views/apps/Design.vue')
    },
    {
      path: '/apps/tryout2.html',
      name: 'TryoutEditor',
      meta: {isPublic:true, analytics: true},
      component: () => import(/* webpackChunkName: "design" */ 'views/apps/Tryout.vue')
    },
    {
      path: '/examples/:id/design/:sid.html',
      name: 'examples_edit',
      meta: {isPublic:true},
      component: () => import(/* webpackChunkName: "design" */ 'views/apps/Design.vue')
    },
    {
      path: '/apps/:id/analyze/workspace.html',
      name: 'apps_analytic_canvas',
      meta: {analytics: true, viewMode: 'Heatmap'},
      component: () => import(/* webpackChunkName: "design" */ 'views/apps/Design.vue')
    },
    {
      path: '/examples/:id/analyze/workspace.html',
      name: 'examples_analytic_canvas',
      meta: {isPublic:true, viewMode: 'Heatmap'},
      component: () => import(/* webpackChunkName: "design" */ 'views/apps/Design.vue')
    },
    {
      path: '/share.html',
      name: 'SharePage',
      component: () => import(/* webpackChunkName: "design" */ 'views/apps/Share.vue')
    },
    {
      path: '/mcu/',
      name: 'mcu',
      children: mcuRoutes,
      component: () => import(/* webpackChunkName: "mcu" */ 'views/mcu/mcu.vue')
    },
    {
      path: '/',
      name: 'home',
      children: matcRoutes,
      component: MATC
    }
  ]
})
